import axios, { isAxiosError } from "axios";
import {
  CampingBooking,
  CampingBookingEndResponse,
  CampingCaptureResponse,
  CampingCheckoutRequest,
  CampingCheckoutResponse,
  CampingQuote,
  CampingQuoteRequest,
  CampingStation,
  Interface,
} from "use-smart-locks-shared";
import { env } from "../../env";
import { queryClient } from "./query-client";

if (!env.nestApiUrl) {
  throw new Error("VITE_NEST_API_URL is not defined");
}
if (!env.nestUnlockBookingUrl) {
  throw new Error("VITE_NEST_UNLOCK_BOOKING_URL is not defined");
}

const api = axios.create({
  baseURL: env.nestApiUrl,
});

export const getApiUserErrorMessage = (error: unknown): string | undefined => {
  if (!isAxiosError(error)) {
    return undefined;
  }

  const data = error.response?.data as unknown;

  if (
    data &&
    typeof data === "object" &&
    "error" in data &&
    "message" in data
  ) {
    // ignore errors that only have a "message" but not an "error" field since those are defaults like "Bad Request"

    return data.message?.toString();
  }

  return undefined;
};

export const getCampingStationBySlug = (
  stationSlug: string,
): Promise<CampingStation> =>
  api
    .get<CampingStation>("/camping/stations/get-by-slug", {
      params: { slug: stationSlug },
    })
    .then((response) => response.data);

export const getCampingQuote = (
  stationId: string,
  data: Interface<CampingQuoteRequest>,
): Promise<CampingQuote> =>
  api
    .post<CampingQuote>(
      `/camping/stations/${encodeURIComponent(stationId)}/checkout/quote`,
      data,
    )
    .then((response) => response.data);

export const getCheckoutUrl = (
  stationId: string,
  data: Interface<CampingCheckoutRequest>,
): Promise<CampingCheckoutResponse> =>
  api
    .post<CampingCheckoutResponse>(
      `/camping/stations/${encodeURIComponent(stationId)}/checkout`,
      data,
    )
    .then((response) => response.data);

export const captureCheckout = (
  stationId: string,
  checkoutId: string,
): Promise<CampingCaptureResponse> =>
  api
    .post<CampingCaptureResponse>(
      `/camping/stations/${encodeURIComponent(stationId)}/checkout/${encodeURIComponent(checkoutId)}/capture`,
    )
    .then((response) => response.data);

export const getBookingBySlug = (
  bookingSlug: string,
): Promise<CampingBooking> =>
  api
    .get<CampingBooking>(`/camping/bookings/get-by-slug`, {
      params: {
        slug: bookingSlug,
      },
    })
    .then((response) => response.data);

export const unlockLocksOfBooking = async (
  bookingId: string,
): Promise<void> => {
  /**
   * Use dedicated handler for unlocking locks because this is a lengthy process exceeding the API Gateway timeout and therefore directly invoked via Lambda URL.
   */
  if (import.meta.env.DEV) {
    await api
      .post<never>(`/camping/locks/booking/${encodeURIComponent(bookingId)}`)
      .then((response) => response.data);
  } else {
    await api.post(env.nestUnlockBookingUrl, {
      bookingId,
    });
  }
  await invalidateBookings();
};

export const endBooking = async (bookingId: string) => {
  const result = await api
    .post<CampingBookingEndResponse>(
      `/camping/bookings/${encodeURIComponent(bookingId)}/end`,
    )
    .then((response) => response.data);
  await invalidateBookings();
  return result;
};

const invalidateBookings = async () => {
  /**
   * Make sure that e.g. startedAt date is updated.
   * We can invalidate specific bookings in the future (by passing the slug), but for now we just invalidate all.
   */
  await queryClient.invalidateQueries({
    queryKey: ["camping-booking"],
  });
};

import {
  UseSuspenseQueryResult,
  useSuspenseQuery,
} from "@tanstack/react-query";
import {
  CampingQuote,
  CampingQuoteRequest,
  Interface,
} from "use-smart-locks-shared";
import { queryClient } from "../../shared/api";
import { getCampingQuote } from "../../shared/api/nest-api";

const queryOptions = (
  campingStationId: string,
  data: Interface<CampingQuoteRequest>,
) => ({
  queryKey: ["camping-quote", campingStationId, ...Object.values(data)],
  queryFn: () => getCampingQuote(campingStationId, data),
});

export const useCampingQuoteQuery = (
  campingStationId: string,
  data: Interface<CampingQuoteRequest>,
): UseSuspenseQueryResult<CampingQuote> =>
  useSuspenseQuery(queryOptions(campingStationId, data));

export const prefetchCampingQuoteQuery = (
  campingStationId: string,
  data: Interface<CampingQuoteRequest>,
): Promise<void> =>
  queryClient.prefetchQuery(queryOptions(campingStationId, data));

import { CheckIcon, SymbolIcon } from "@radix-ui/react-icons";
import {
  Box,
  Button,
  Callout,
  Flex,
  Heading,
  Separator,
} from "@radix-ui/themes";
import { useMutation } from "@tanstack/react-query";
import { addMinutes, isPast } from "date-fns";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  CampingBooking,
  CampingBookingEndResponse,
  END_BOOKING_PROCESS_AVAILABLE_DELAY_AFTER_START_MINUTES,
  RequirementStatus,
  RequirementType,
} from "use-smart-locks-shared";
import {
  endBooking,
  getApiUserErrorMessage,
} from "../../../shared/api/nest-api";

type CounterInfo = {
  current: number;
  total: number;
};

const buttonText = "Fahrräder zurückgeben";
const infoText = `Bitte schließen Sie alle Fahrräder an und legen Sie alle Schlüssel zurück in die Boxen. Drücken Sie dann auf “${buttonText}”.`;

export function EndBooking({ booking }: { booking: CampingBooking }) {
  const { mutateAsync: endBookingMutation, isPending } = useMutation({
    mutationFn: endBooking,
    onError(error) {
      toast.error(
        getApiUserErrorMessage(error) ??
          "Leider ist ein unerwarteter Fehler aufgetreten.",
      );
    },
  });

  const [infoState, setInfoState] = useState<"default" | "error" | "success">(
    "default",
  );
  const [unitsConnectedInfo, setUnitsConnectedInfo] =
    useState<CounterInfo | null>(null);
  const [keysInPlaceInfo, setKeysInPlaceInfo] = useState<CounterInfo | null>(
    null,
  );

  useEffect(() => {
    if (booking.endedAt) {
      setInfoState("success");
    }
  }, [booking.endedAt]);

  const [enableEndBooking, setEnableEndBooking] = useState(false);
  useEffect(() => {
    const evaluateVisibility = () => {
      setEnableEndBooking(
        booking.startedAt
          ? isPast(
              addMinutes(
                booking.startedAt,
                END_BOOKING_PROCESS_AVAILABLE_DELAY_AFTER_START_MINUTES,
              ),
            )
          : false,
      );
    };

    evaluateVisibility();
    const interval = setInterval(evaluateVisibility, 10_000);
    return () => clearInterval(interval);
  }, [booking.startedAt]);

  const endBookingWrapper = async () => {
    const result = await endBookingMutation(booking.id);
    if (result.success) {
      setInfoState("success");
      return;
    }

    setInfoState("error");
    const allCounts = getCountsOfAllRequirements(result);
    setUnitsConnectedInfo(
      allCounts.charging.total > 0 ? allCounts.charging : null,
    );
    setKeysInPlaceInfo(
      allCounts.keyreturn.total > 0 ? allCounts.keyreturn : null,
    );
  };

  return (
    enableEndBooking && (
      <Flex direction="column" gap="4" minWidth="100%">
        <Separator my="4" className="full-width" />
        <Flex direction="column" gap="4" minWidth="100%">
          <Heading size="2">Rückgabe:</Heading>
          {infoState === "default" && <FinishBookingInfoDefault />}
          {infoState === "error" && (
            <FinishBookingInfoError
              {...{ unitsConnectedInfo, keysInPlaceInfo }}
            />
          )}
          {infoState === "success" && <FinishBookingInfoSuccess />}
          <Button
            disabled={infoState === "success"}
            onClick={() => void endBookingWrapper()}
            // TODO: make it a little more obious that the locks are unlocked
            color="red"
            loading={isPending}
          >
            Fahrräder zurückgeben
          </Button>
        </Flex>
      </Flex>
    )
  );
}

function getCountsOfAllRequirements(
  endBookingResult: CampingBookingEndResponse,
): Record<RequirementType, CounterInfo> {
  const result: Record<RequirementType, CounterInfo> = {
    charging: { current: 0, total: 0 },
    keyreturn: { current: 0, total: 0 },
  };

  endBookingResult.unitStatuses
    .flatMap((unitStatus) => unitStatus.requirements)
    .filter(
      (requirement) => requirement.status !== RequirementStatus.NotApplicable,
    )
    .forEach((requirement) => {
      result[requirement.type].total++;
      if (requirement.status === RequirementStatus.Fulfilled) {
        result[requirement.type].current++;
      }
    });
  return result;
}

function FinishBookingInfoDefault() {
  return (
    <Callout.Root color="gray">
      <Callout.Icon>
        <SymbolIcon />
      </Callout.Icon>
      <Callout.Text>{infoText}</Callout.Text>
    </Callout.Root>
  );
}

function FinishBookingInfoError({
  unitsConnectedInfo,
  keysInPlaceInfo,
}: {
  /** Pass null to hide this information. */
  unitsConnectedInfo: CounterInfo | null;
  /** Pass null to hide this information. */
  keysInPlaceInfo: CounterInfo | null;
}) {
  const showUnitsConnected = unitsConnectedInfo !== null;
  const showKeysInPlace = keysInPlaceInfo !== null;
  const showDetails = showUnitsConnected || showKeysInPlace;

  const unitsConnectedText = showUnitsConnected
    ? `${unitsConnectedInfo.current.toString()} / ${unitsConnectedInfo.total.toString()} Fahrräder angeschlossen`
    : "";
  const keysInPlaceText = showKeysInPlace
    ? `${keysInPlaceInfo.current.toString()} / ${keysInPlaceInfo.total.toString()} Schlüssel zurückgelegt`
    : "";

  return (
    <Callout.Root color="red">
      <Callout.Icon>
        <SymbolIcon />
      </Callout.Icon>
      {/* 
        Spreading the 'as' property is a hackfix to overwrite the element of the text node.
        Otherwise, the nested 'ul' results in an error: "Warning: validateDOMNesting(...): <ul> cannot appear as a descendant of <p>."
      */}
      <Callout.Text {...{ as: "div" }}>
        {infoText}
        {showDetails && (
          <Box ml="6" mt="1">
            <ul>
              {unitsConnectedText && <li>{unitsConnectedText}</li>}
              {keysInPlaceText && <li>{keysInPlaceText}</li>}
            </ul>
          </Box>
        )}
      </Callout.Text>
    </Callout.Root>
  );
}

function FinishBookingInfoSuccess() {
  return (
    <Callout.Root color="green">
      <Callout.Icon>
        <CheckIcon />
      </Callout.Icon>
      <Callout.Text>
        Ihre Buchung wurde beendet, vielen Dank für Ihr Vertrauen!
      </Callout.Text>
    </Callout.Root>
  );
}
